@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  min-width: 326px;
  background: #f5f6f8;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* height: 5000px; */
}

main {
  flex-grow: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

button {
  border: 0;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
